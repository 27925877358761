import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Button, Checkbox, Container, Divider, FormHelperText, FormControl, FormControlLabel, Grid, Typography, MenuItem, Link, TextField, Tab, Select, InputLabel, Radio, RadioGroup, makeStyles } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Formik } from 'formik';
import StepperCart from '../../components/FormsUI/StepperCart';
import PhoneInput from 'react-phone-input-2';
import * as Yup from 'yup';
import { useNavigate, useLocation } from "react-router-dom";
import useCountryList from '../../utils/countryList';
import { getPublicIp } from '../../utils/index'
import { authUser, validateUser } from '../../services/User';
import { saveAuth } from '../../hooks/useAuth';
import SpinnerLoad from '../../components/FormsUI/Spinner';
import { createFullDomainRecord } from '../../services/Invoice';
import DomainLoginComponent from './DomainLoginComponent';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    radioPay: {
      height: "75px",
      width: "100%",
    }
  },
}));

const DomainCheckout = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  let domain = '';
  let pricedomain = 0.00;
  let pricerenewdomain = 0.00;
  let freedomain = null;
  let eventDomain = 'domainregister';
  let subTotalAdd = 0;
  let subtotal = 0.00;
  let total = 0;
  let billingcycle = '';
  let domainaddons = [];
  let nameservers = [];
  let qtyanios = 1;
  let coupon = null;
  let purchaserecordtype = 'singledomain';
  let epp = '';

  if (location.state !== null) {
    domainaddons = location.state.data.domainaddons;
    nameservers = location.state.data.nameservers;
    subTotalAdd = location.state.data.subtotaladd;
    subtotal = location.state.data.subtotal;
    total = location.state.data.total;
    domain = location.state.data.domain;
    eventDomain = location.state.data.eventdomain;
    freedomain = location.state.data.freedomain;
    billingcycle = location.state.data.billingcycle;
    qtyanios = location.state.data.qtyanios;
    coupon = location.state.data.coupon;
    purchaserecordtype = location.state.data.purchaserecordtype;
    epp = location.state.data.epp;

    pricedomain = location.state.data.pricedomain;
    pricerenewdomain = location.state.data.pricerenewdomain;
  }

  const countries = useCountryList();

  const inputPhone = useRef();

  const [codcountry, setcodcountry] = useState('US');
  const [codcountryphone, setcodcountryphone] = useState('us');
  const [phonenumber, setPhone] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  const [errorPhone, setErrorPhone] = useState(false);
  const [errorUser, setErrorUser] = useState(false);

  const [isClient, setIsClient] = useState(false);


  //tabs
  const [valueTab, setValueTab] = useState('1');
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  }

  //payment
  const [paymentgw, setpaymentgw] = useState([]);
  const [payment, setpayment] = useState('tco');

  const [paymentbo, setpaymentbo] = useState([{ id: 235, gateway: "banktransfer", value: "BOL (Pago en Bancos)" }]);
  const [paymentus, setpaymentus] = useState([{ id: 4, gateway: "tco", value: "Tarjeta de crédito" }]);
  const [paymentcolmexper, setpaymentcolmexper] = useState([
    { id: 28, gateway: "stripe", value: "Tarjeta de Crédito / Débito" },
    { id: 102, gateway: "paypal", value: "PayPal" },
    { id: 4, gateway: "tco", value: "Tarjeta de crédito" },
    //{ id: 230, gateway: "payu", value: "COL, MEX, PER  ( Pago Local )" },
    { id: 230, gateway: "payu", value: "MEX, PER  ( Pago Local )" },
    { id: 12, gateway: "mailin", value: "Western Union" },
  ]);
  const [paymentall, setpaymentall] = useState([
    { id: 28, gateway: "stripe", value: "Tarjeta de Crédito / Débito" },
    { id: 102, gateway: "paypal", value: "PayPal" },
    { id: 4, gateway: "tco", value: "Tarjeta de crédito" },
    { id: 12, gateway: "mailin", value: "Western Union" },
  ]);

  const handlePaymentGw = (cc) => {
    if (cc === 'bo') {
      setpaymentgw(paymentbo)
      setpayment('banktransfer')
    } else {
      if (cc === 'us') {
        setpaymentgw(paymentus)
        setpayment('tco')
      } else {
        /*if (cc === 'mx' || cc === 'pe' || cc === 'co') {
          setpaymentgw(paymentcolmexper)
          setpayment('stripe')
        } else {
          setpaymentgw(paymentall)
          setpayment('stripe')
        } */
        
        if (cc === 'mx' || cc === 'pe') {
          setpaymentgw(paymentcolmexper)
          setpayment('stripe')
        } else {
          setpaymentgw(paymentall)
          setpayment('stripe')
        }

      }
    }
  };

  const handlePaymentSelect = (ev) => {
    setpayment(ev.target.value)
  }


  const handleChangeCountry = ev => {
    const value = ev.target.value;
    const cc = value.toLowerCase();

    setcodcountry(value);
    setcodcountryphone(cc);
    setPhone(null);

    handlePaymentGw(cc);

  }
  const handlePhoneNumber = ev => {

    const statePhone = inputPhone.current.state;

    setErrorPhone(false);
    setPhone(statePhone.formattedNumber);
    setCountryCode(statePhone.selectedCountry.countryCode);

    //setPhone(ev.target.value)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (domain === '') {
      navigate('/')
    }

  }, []);

  if (domain === '') {
    return (
      <SpinnerLoad />
    )
  } else {
    return (
      <>
        <Helmet>
          <title>Portal - Área de clientes de Neothek - Registrar</title>
        </Helmet>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center'
          }}
        >

          <Container maxWidth="md">
            <Grid item xs={12} md={12}>
              <StepperCart step={2} />
            </Grid>
          </Container>

          <Container maxWidth="md" variant='cont-nk'>
            <Grid container>
              {/* tabs */}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={valueTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                      <Tab label="Crear nueva cuenta" value="1" sx={{ fontSize: '11px' }} />
                      <Tab label="Ya soy cliente" value="2" sx={{ fontSize: '11px' }} />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    {/* nueva cuenta */}
                    <Box sx={{ py: 1 }}>
                      <Grid item xs={12} md={12}>
                        <Formik
                          initialValues={{
                            email: '',
                            firstname: '',
                            lastname: '',
                            country: 'US',
                            city: '',
                            state: '',
                            address1: '',
                            address2: '',
                            companyname: '',
                            postcode: '',
                            phonenumber: '',
                            password: '',
                            password2: '',
                            policy: false
                          }}
                          validationSchema={
                            Yup.object().shape({
                              firstname: Yup.string().max(255).required('Nombre es requerido'),
                              lastname: Yup.string().max(255).required('Apellido es requerido'),
                              email: Yup.string().email('Ingrese un e-mail válido').max(255).required('E-mail es requerido'),
                              city: Yup.string().required('Ciudad es requerida'),
                              state: Yup.string().required('Provincia/Estado es requerido'),
                              address1: Yup.string().required('Dirección 1 es requerido'),
                              address2: Yup.string(),
                              companyname: Yup.string(),
                              postcode: Yup.string()
                                .matches(/^[0-9A-Za-z- ]+$/, 'Por favor ingrese un código postal válido')
                                .min(4, 'Se acepta mínimo 4 dígitos')
                                .max(10, 'Se acepta máximo 10 dígitos')
                                .required('Código Postal es requerido'),
                              //phonenumber: Yup.number().required('Número de teléfono es requerido'),
                              password: Yup.string().min(8, 'Usa 8 caracteres como mínimo').max(255).required('Contraseña es requerida'),
                              password2: Yup.string().oneOf([Yup.ref('password'), null], 'La contraseña escrita no coincide'),
                              policy: Yup.boolean().oneOf([true], 'Este campo debe estar marcado')
                            })
                          }
                          onSubmit={async (val, actions) => {

                            //verificar email ya registrado
                            setIsClient(false);

                            if (phonenumber === null || phonenumber === '') {
                              setErrorPhone(true);
                            } else {
                              //validar que email no esté registrado
                              const user = await validateUser(val.email);

                              if (user.error === false) {
                                if (user.isuser === true) {
                                  setIsClient(true);
                                }
                                if (user.isuser === false) {

                                  //crear full order

                                  const ip = await getPublicIp();
                                  val.country = codcountry;
                                  val.phonenumber = phonenumber;
                                  val['countrycode'] = countryCode;
                                  val['ip'] = ip;
                                  val['defaultgateway'] = payment;
                                  //console.log('register new', val);

                                  //console.log('dmain', pricedomain);
                                  //console.log('renew dmain', pricerenewdomain);

                                  const pricedomaindec = pricedomain.toFixed(2);
                                  const pricerenewdomaindec = pricerenewdomain.toFixed(2);

                                  const order = {
                                    subtotaladd: subTotalAdd,
                                    subtotal: subtotal,
                                    total: total,
                                    domain: domain,
                                    pricedomain: pricedomaindec,
                                    pricerenewdomain: pricerenewdomaindec,
                                    eventdomain: eventDomain,
                                    freedomain: freedomain,
                                    billingcycle: billingcycle,
                                    paymentmethod: payment,
                                    ipaddress: ip,
                                    domainaddons: domainaddons,
                                    nameservers: nameservers,
                                    qtyanios: qtyanios,
                                    coupon: coupon,
                                    purchaserecordtype: purchaserecordtype,
                                    epp: epp,
                                    status: 'buyinprogress'
                                  }

                                  const data = { ...{ order: order }, ...{ client: val } };
                                  //console.log('data a enviar', data)

                                  const invoice = await createFullDomainRecord(data);

                                  //si la orden y user se crea correctamente
                                  if (invoice.error === false) {
                                    //loguear usuario y direccionar a invoice
                                    const auth = await authUser(val.email, val.password); //verificar error en authuser

                                    if (auth.error === false) {
                                      saveAuth(auth);
                                      navigate(`/clientarea/invoices/${invoice.id}/`);
                                    }

                                  }

                                }
                              }

                            }

                          }}
                        >
                          {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values
                          }) => (


                            <form onSubmit={handleSubmit}>

                              {errorUser && <Typography sx={{ color: '#f44336', fontSize: '1.2rem', paddingLeft: '3%' }}>Ha existido un error al crear la cuenta, por favor llene el formulario nuevamente.</Typography>}

                              <Grid container spacing={1}>
                                {isClient === true &&
                                  <Grid item xs={12} md={12}>
                                    <Typography sx={{ color: '#f44336', fontSize: '1.3rem', paddingLeft: '3%' }}>La cuenta e-mail ya se encuentra registrada, por favor utilice la opción "Ya soy cliente"</Typography>
                                  </Grid>
                                }

                                <Grid item xs={12} md={6}>
                                  <TextField
                                    error={Boolean(touched.firstname && errors.firstname)}
                                    fullWidth
                                    helperText={touched.firstname && errors.firstname}
                                    label="Nombre"
                                    margin="normal"
                                    name="firstname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstname}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    error={Boolean(touched.lastname && errors.lastname)}
                                    fullWidth
                                    helperText={touched.lastname && errors.lastname}
                                    label="Apellido"
                                    margin="normal"
                                    name="lastname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastname}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Dirección E-mail"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                    size="small"
                                  />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    error={Boolean(touched.companyname && errors.companyname)}
                                    fullWidth
                                    helperText={touched.companyname && errors.companyname}
                                    label="Compañía (Opcional)"
                                    margin="normal"
                                    name="companyname"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.companyname}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <FormControl sx={{ mt: 2, minWidth: '100%' }}>
                                    <InputLabel id="labelCountry" sx={{ ml: 1.7, mt: -1 }}>País</InputLabel>
                                    <Select
                                      labelId="labelCountry"
                                      error={Boolean(touched.country && errors.country)}
                                      fullWidth
                                      //helperText={touched.country && errors.country}
                                      label="País"
                                      name="country"
                                      onBlur={handleBlur}
                                      onChange={handleChangeCountry}
                                      value={codcountry}
                                      variant="outlined"
                                      size="small"
                                    >

                                      {countries.map(({ value, label }) => (
                                        <MenuItem key={value} value={value}>
                                          {label}
                                        </MenuItem>
                                      ))}


                                    </Select>
                                  </FormControl>

                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    error={Boolean(touched.state && errors.state)}
                                    fullWidth
                                    helperText={touched.state && errors.state}
                                    label="Provincia/Estado"
                                    margin="normal"
                                    name="state"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.state}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <TextField
                                    error={Boolean(touched.city && errors.city)}
                                    fullWidth
                                    helperText={touched.city && errors.city}
                                    label="Ciudad"
                                    margin="normal"
                                    name="city"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.city}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                                  <PhoneInput
                                    error={Boolean(touched.phonenumber && errors.phonenumber)}
                                    fullWidth
                                    helperText={touched.phonenumber && errors.phonenumber}
                                    country={codcountryphone}
                                    specialLabel='Teléfono'
                                    name="phonenumber"
                                    phonenumber
                                    onBlur={handleBlur}
                                    onChange={handlePhoneNumber}
                                    value={values.phonenumber}
                                    ref={inputPhone}
                                  />
                                  {errorPhone && <Typography sx={{ color: '#f44336', fontSize: '1.2rem', paddingLeft: '3%' }}>Teléfono es requerido</Typography>}
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <TextField
                                    error={Boolean(touched.address1 && errors.address1)}
                                    fullWidth
                                    helperText={touched.address1 && errors.address1}
                                    label="Dirección 1"
                                    margin="normal"
                                    name="address1"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.address1}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <TextField
                                    error={Boolean(touched.address2 && errors.address2)}
                                    fullWidth
                                    helperText={touched.address2 && errors.address2}
                                    label="Dirección 2 (Opcional)"
                                    margin="normal"
                                    name="address2"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.address2}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <TextField
                                    error={Boolean(touched.postcode && errors.postcode)}
                                    fullWidth
                                    helperText={touched.postcode && errors.postcode}
                                    label="Código Postal"
                                    margin="normal"
                                    name="postcode"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.postcode}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password || 'Utiliza 8 caracteres como mínimo: letras, números y símbolos'}
                                    label="Contraseña"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.password}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    error={Boolean(touched.password2 && errors.password2)}
                                    fullWidth
                                    helperText={touched.password2 && errors.password2}
                                    label="Confirmar contraseña"
                                    margin="normal"
                                    name="password2"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.password2}
                                    variant="outlined"
                                    size="small"
                                  />
                                </Grid>

                              </Grid>

                              <Grid container>
                                <Grid item xs={12}>
                                  <Box mt={5} mb={2} >
                                    <Typography variant='h4'>Medios de Pago</Typography>
                                    <Divider />
                                    {paymentgw.length === 0 ?
                                      <FormControl component="fieldset" sx={{display: "inherit"}}>
                                        <RadioGroup aria-label="payment" name="paymentmethod" value='tco' row>
                                          {paymentus.map(({ id, gateway, value }) => (
                                            <FormControlLabel key={id} value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} />
                                          ))}
                                        </RadioGroup>
                                      </FormControl>

                                      :

                                      <FormControl component="fieldset" sx={{display: "inherit"}}>
                                        <RadioGroup aria-label="payment" name="paymentmethod" value={payment} onChange={handlePaymentSelect} row>
                                          {paymentgw.map(({ id, gateway, value }) => (
                                            <React.Fragment key={id} >
                                              {gateway === "tco" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/cards_2co.webp)", backgroundSize: "47.2%" }} />}
                                              {gateway === "stripe" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/cards_stripe.webp)", backgroundSize: "48%" }} />}
                                              {gateway === "paypal" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/paypal.webp)", backgroundSize: "22%", backgroundPosition: "16% 80%" }} />}
                                              {gateway === "payu" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/payu.webp)", backgroundSize: "58%", backgroundPosition: "30% 80%" }} />}
                                              {gateway === "banktransfer" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/bancos_bol.webp)", backgroundSize: "39%" }} />}
                                              {gateway === "mailin" && <FormControlLabel value={gateway} control={<Radio color="primary" />} label={value} variant="labelPay-nk" className={classes.radioPay} sx={{ backgroundImage: "url(/static/images/payments/wu_2.webp)", backgroundSize: "23%", backgroundPosition: "15% 80%" }} />}
                                            </React.Fragment>
                                          ))}
                                        </RadioGroup>
                                      </FormControl>
                                    }

                                  </Box>
                                </Grid>

                                <Grid item xs={12}>

                                </Grid>

                              </Grid>

                              <Grid container sx={{ mb: 4 }}>
                                <Grid item xs={12}>
                                  <Box mt={2} mb={2} >
                                    <Typography variant='h4'>Sumario de Pedido</Typography>
                                    
                                  </Box>
                                </Grid>

                                <Grid item xs={12}>
                                  <Container sx={{ backgroundColor: "#F6F9FC", borderRadius: "5px", p: 1, paddingTop: "4%" }}>
                                    <Grid container spacing={2} sx={{ mb: 2 }}>

                                      <Grid item sm={8} xs={9}>
                                        {eventDomain === 'domainregister' ?
                                          <Typography color="colornk.bluedark" variant="txtSummary-nk">Registro de Dominio - {domain} / {qtyanios} año(s)</Typography>
                                            :
                                          <Typography color="colornk.bluedark" variant="txtSummary-nk">Transferencia de Dominio - {domain} / {qtyanios} año(s)</Typography>
                                        }

                                      </Grid>
                                      <Grid item sm={4} xs={3} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                        <Typography color="colornk.bluedark" variant="txtSummary-nk">US$ {pricedomain}</Typography>
                                      </Grid>
                                      <Grid item sm={12} style={{ paddingTop: 0 }}>
                                        <Typography style={{ fontSize: '1.18rem' }} color='colornk.grey3'>Renovación ${pricerenewdomain} / {qtyanios} año(s)</Typography>
                                      </Grid>

                                      <Grid item sm={8} xs={9} style={{ paddingTop: '13px' }}>
                                        <Typography color="colornk.bluedark" variant="txtSummary-nk">Complementos</Typography>
                                      </Grid>
                                      <Grid item sm={4} xs={3} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                        <Typography color="colornk.bluedark" variant="txtSummary-nk">US$ {subTotalAdd}</Typography>
                                      </Grid>

                                      <Grid item xs={12} sx={{ borderTop: '1px solid #e6ebf1' }} style={{ marginTop: '10px', paddingTop: '0px' }}></Grid>

                                      <Grid item sm={8} xs={8} style={{ paddingTop: '13px' }}>
                                        <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>Subotal</strong></Typography>
                                      </Grid>
                                      <Grid item sm={4} xs={4} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                        <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>US$ {subtotal}</strong></Typography>
                                      </Grid>

                                      <Grid item sm={8} xs={8} style={{ paddingTop: '13px' }}>
                                        <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>Total</strong></Typography>
                                      </Grid>
                                      <Grid item sm={4} xs={4} style={{ paddingTop: '13px', textAlign: 'right' }}>
                                        <Typography color="colornk.bluedark" variant="txtSummary-nk"><strong>US$ {total}</strong></Typography>
                                      </Grid>

                                    </Grid>
                                  </Container>

                                </Grid>

                              </Grid>


                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  ml: -1
                                }}
                              >
                                <Checkbox
                                  checked={values.policy}
                                  name="policy"
                                  onChange={handleChange}
                                />
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  Estoy de acuerdo con los
                                  {' '}
                                  <Link
                                    color="primary"
                                    component="a"
                                    href="http://www.neothek.com/legal/"
                                    underline="always"
                                    variant="body2"
                                    target="_blank"
                                    rel="noopener"
                                  >
                                    Términos y condiciones de Uso
                                  </Link>
                                </Typography>
                              </Box>
                              {Boolean(touched.policy && errors.policy) && (
                                <FormHelperText error>
                                  {errors.policy}
                                </FormHelperText>
                              )}
                              <Box sx={{ py: 3 }}>
                                <Button
                                  color="success"
                                  disabled={isSubmitting}
                                  fullWidth
                                  size="large"
                                  type="submit"
                                  variant="contained"
                                >
                                  Completar Pedido
                                </Button>
                              </Box>

                            </form>

                          )}
                        </Formik>

                      </Grid>
                    </Box>
                    {/* nueva cuenta*/}
                  </TabPanel>
                  <TabPanel value="2" style={{ paddingRight: 0, paddingLeft: 0 }}>
                    {/* login */}
                    <Box sx={{ py: 1 }}>
                      <Grid item xs={12} md={12}>
                        <DomainLoginComponent />
                      </Grid>
                    </Box>
                    {/* login */}
                  </TabPanel>
                </TabContext>
              </Box>

            </Grid>


          </Container>
        </Box>
      </>
    )
  }


}

export default DomainCheckout;
